import React, {useMemo, useState} from 'react';
import AuthContext from "../context/AuthContext";
import UseSalesBuilderCookie from "../hooks/UseSalesBuilderCookie"

export const AuthProvider = (props) => {
    const [pingFedUser, setPingFedUser] = useState(null);
    const [cookies, setRecords, removeRecords ] = UseSalesBuilderCookie()
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = cookies?.accessToken ?? "";
        return !!token
    });

    const [userRecords, setUserRecords] = useState(() => {
       return cookies 
    });

    const isSessionExpired = (expires_at) => {
        if (expires_at) {
            const now = Math.floor(Date.now() / 1000);
            return now >= expires_at;
        }
        return true;
    }

    const logout = async () => {
        localStorage.clear();
        removeRecords();
        const base_url = process.env.REACT_APP_GENAI_SALES_AGENDA_BACKEND_BASE_URL;
        // const base_url = 'https://sales-agenda-builder-api.emerging-tech.mdev1.cld.marriott.com/agenda-builder'
        // const base_url = 'http://localhost:5000/agenda-builder'
        setIsAuthenticated(false);
        window.location.href = `${base_url}/logout`;
    }

    const clearUserSession = () => {
        removeRecords();
    }

    const addUserSession = (records, options) => {
        setRecords(records, options);
    }

    const authContextValue = useMemo(() => ({
        logout,
        isSessionExpired,
        pingFedUser,
        setPingFedUser,
        setIsAuthenticated,
        isAuthenticated,
        userRecords,
        setUserRecords,
        clearUserSession,
        addUserSession
    }), [pingFedUser, setPingFedUser, setIsAuthenticated, isAuthenticated, userRecords, setUserRecords]);

    return (
        <AuthContext.Provider value={authContextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};
