import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

export const CustomLargeTextCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [remainingChars, setRemainingChars] = useState(props.maxLength - props.value.length);
  const textAreaRef = useRef(null);

  useEffect(() => {
    // Focus on the text area
    textAreaRef.current.focus();
    textAreaRef.current.select();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      isCancelBeforeStart: () => {
        return false;
      },
      isCancelAfterEnd: () => {
        // Additional logic to determine if editing should be cancelled
      },
      afterGuiAttached: () => {
        // Select all text
        textAreaRef.current.select();
      }
    };
  });

  const handleChange = event => {
    setValue(event.target.value);
    setRemainingChars(props.maxLength - event.target.value.length);
  };

  return (
      <div className="position-relative">
    <textarea
        ref={textAreaRef}
        value={value}
        onChange={handleChange}
        className="form-control mb-0" // Bootstrap class for form control and margin bottom
        style={{ height: (props.rows * 18) + 'px', width: (props.cols * 10) + 'px' }} // Inline styles for dimensions
        maxLength={props.maxLength}
    />
        <div className="position-absolute bottom-0 end-0 p-2 text-secondary rounded small"
             style={{ right: '5px', bottom: '5px' }}> {/* Additional positioning */}
          {remainingChars} characters remaining
        </div>
      </div>
  );
});
