import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = ()=> {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
            <h1 className="display-1">404</h1>
            <p className="fs-3">Page Not Found</p>
            <Link to="/" className="btn btn-primary">Go Home</Link>
        </div>
    );

}

export default NotFound;