import { useCallback } from "react"
import { useCookies } from "react-cookie";

export default function UseSalesBuilderCookie(records) {
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    
    const removeRecords = () => {
        removeCookie("accessToken")
        removeCookie("userInitial")
        removeCookie("expiresAt")
        removeCookie("expiresIn")
        removeCookie("userName")
    }

    const setRecords = useCallback(
        (recValue, options={ path: "/", expires: undefined }) => {
            // Set expiry time
            let hours = 2
            let expires = new Date();

            if(options && typeof options.expires === 'number') {
                expires.setTime(new Date().getTime() + (options.expires * 1000));
            } else {
                expires.setTime(new Date().getTime() + (hours * 60 * 60 * 1000));
            }

            // Correctly set the 'expires' option to a Date object
            options.expires = expires;

            // Loop all records and set on cookies
            for (const key in recValue) {
                setCookie(key, recValue[key], options)
            }
        }, [setCookie]
    )
    
    return [cookies, setRecords, removeRecords]
}