import React, { useContext, useEffect, useState } from 'react';
import DragDropFiles from "./DragDropFiles";
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";
import { getDateErrMsg, changeDateFormat, validateDate } from "../utils/common-utils"

const Upload = () => {
    const { isSessionExpired, pingFedUser, setPingFedUser, logout, userRecords } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isRagChecked, setRagChecked] = useState(true);
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [start_date_formatted, setFormattedStartDate] = useState("");
    const [end_date_formatted, setFormattedEndDate] = useState("");
    const [dt_error, setDateError] = useState("");

    useEffect(() => {
        // if the user session has expired. Clear the localstorage and Navigate the user to login page
        let navigatedFrom = location?.state?.from?.pathname;
        let expires_at = userRecords?.expiresAt ?? "";
        let userName = userRecords?.userName ?? "";

        if (navigatedFrom === '/loginCallBack' && !isSessionExpired(expires_at) && userName) {
            setPingFedUser(userName);
        } else if (navigatedFrom !== '/loginCallBack' && (isSessionExpired(expires_at))) {
            localStorage.clear();
            logout()
            navigate('/login');
        }
    }, [isSessionExpired, location, navigate, pingFedUser, logout, setPingFedUser, userRecords]);

    useEffect(() => {
        let errMsg = ""
        if (start_date_formatted.length === 10) {
            if (validateDate(start_date_formatted, 'M')) {
                let start_date_ori = changeDateFormat(start_date_formatted, 'MDY2YMD')
                if (start_date !== start_date_ori) {
                    setStartDate(start_date_ori)
                }
            }
            else {
                errMsg = "Invalid start date."
            }
        }

        if (end_date_formatted.length === 10) {
            if (validateDate(end_date_formatted, 'M')) {
                let end_date_ori = changeDateFormat(end_date_formatted, 'MDY2YMD')
                if (end_date !== end_date_ori) {
                    setEndDate(end_date_ori)
                }
            }
            else {
                errMsg = "Invalid end date."
            }
        }
        if (errMsg !== "") {
            setDateError(errMsg)
        }
    }, [start_date_formatted, end_date_formatted]);


    useEffect(() => {
        const st_dt_formatted = changeDateFormat(start_date)
        const ed_dt_formatted = changeDateFormat(end_date)

        if (st_dt_formatted !== start_date_formatted)
            setFormattedStartDate(st_dt_formatted)

        if (ed_dt_formatted !== end_date_formatted)
            setFormattedEndDate(ed_dt_formatted)

        setDateError(getDateErrMsg(start_date, end_date))
    }, [start_date, end_date]);

    return (
        <>
            <br />
            <div className="container text-center txt-left">
                <h2 className='txt-left upload-header'>Event Agenda Builder</h2>
                <br />
                <p className='txt-left'>Upload a customer submission request to auto generated an event agenda. Once successfully generated, you will be able to make any necessary edits to the file in order to submit data to the S&C system.</p>
                <h5 style={{ fontWeight: 'bold' }}
                    className='txt-left upload-header customFileUploadFontColor'>Upload File
                </h5>
                <div style={{ textAlign: "left" }}>
                    <input type="checkbox" id="rag" name="rag" value="rag" defaultChecked={isRagChecked} onChange={() => setRagChecked((state) => !state)} /> &nbsp;
                    <label htmlFor="rag">RAG (Use for larger files)</label><br /><br />
                </div>

                <div className="dt_table">

                    <div className="dt_column first">Start Date</div>
                    <div className="dt_column">
                        <input type="text" value={start_date_formatted} onChange={(e) => setFormattedStartDate(e.target.value)} placeholder='MM/DD/YYYY' />
                        <input type="date" value={start_date} onChange={(e) => setStartDate(e.target.value)} className='dt_input' />
                    </div>

                    <div className="dt_column first">End Date</div>
                    <div className="dt_column">
                        <input type="text" value={end_date_formatted} onChange={(e) => setFormattedEndDate(e.target.value)} placeholder='MM/DD/YYYY' />
                        <input type="date" value={end_date} onChange={(e) => setEndDate(e.target.value)} className='dt_input' />
                    </div>
                </div>

                <div className='clear'></div>

                <div style={{ textAlign: "left", color: "red" }}>{dt_error}</div>
                <div className='clear'></div>

                <br />
                <DragDropFiles isRagChecked={isRagChecked} start_date={start_date} end_date={end_date} dt_error={dt_error} />
            </div>
        </>
    );
}

export default Upload;