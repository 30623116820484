import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import marriotLogo from '../marriott.logo.png';
import AuthContext from "../context/AuthContext";

export default function Navbar(props) {
    const { pathname, state } = useLocation();
    const [open, setOpen] = React.useState(false);
    const { logout, userRecords } = useContext(AuthContext);
    const handleOpen = () => {
        setOpen(!open);
    };

    let initial = userRecords?.userInitial || "";
    initial = !initial && state && state['userInitial'] ? state['userInitial'] : initial

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <img
                        src={marriotLogo}
                        className="d-inline-block align-top brand-image justify-content-center"
                        alt="React Bootstrap logo"
                    />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {pathname && initial && initial !== "" && (
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <h6 data-letters={initial} className="dropdown" onClick={handleOpen}>&nbsp;</h6>
                                    {open ? (
                                        <ul className="menu">
                                            <li className="menu-item">
                                                <button onClick={logout}>Logout</button>
                                            </li>
                                        </ul>
                                    ) : null}
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
}