import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from "../context/AuthContext";
import {
  generateFileWithUniqueName,
  generateUniqueFileName,
  getMimeType,
  getValidAndInValidFileList,
} from '../utils/common-utils';
import { postAxiosCall, putAxiosCall } from "../api/axiosConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const DragDropFiles = (props) => {
  const navigate = useNavigate();
  const { userRecords, clearUserSession } = useContext(AuthContext);
  const allowedFileExtensions = [
    '.pdf',
    '.docx',
    '.xlsx',
    '.msg'
  ];

  const [uploadState, setUploadState] = useState({
    status: 'idle',
    agendaFile: null,
    agendaFilesNotValid: null,
    uploadPercent: 0,
    preSignedUrl: null,
    uniqueFileName: null,
    fileWithUniqueName: null,
    originalFile: null,
    apiToken: userRecords?.accessToken ?? null,
    invalidToken: false,
    isDragActive: false,
    showError: false
  });

  let inputRef = useRef();
  const handleDragOver = (event) => {
    event.preventDefault();
    // Update the state
    setUploadState({
      ...uploadState,
      isDragActive: true
    });
  };
  const handleFileOnClick = (event) => {
    event.persist();
    // setUploadPercent(0.5);
    // we are checking the agendaFile is valid or not in addition to the browser - just in case if the browser misses
    const finalFileList = getValidAndInValidFileList([...event.target.files], allowedFileExtensions);

    // Update the state
    setUploadState({
      ...uploadState,
      agendaFile: finalFileList.validFileList,
      agendaFilesNotValid: finalFileList.inValidFileList,
      uploadPercent: 0.5,
      showError: false,
      status: "pending"
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const finalFileList = getValidAndInValidFileList([...event.dataTransfer.files], allowedFileExtensions);

    // update the state
    setUploadState({
      ...uploadState,
      agendaFile: finalFileList.validFileList,
      agendaFilesNotValid: finalFileList.inValidFileList,
      uploadPercent: 0.5,
      status: 'pending',
      isDragActive: false
    });
  };

  const handleDivClick = (event) => {
    event.persist();
    inputRef.current.click();
  };

  const handleGenerateAgenda = () => {
    navigate('/generatePage', {
      state: {
        fileName: uploadState.uniqueFileName,
        originalFile: uploadState.originalFile,
        isRagChecked: props.isRagChecked || false,
        start_date: props.dt_error === '' && props.start_date && props.end_date ? props.start_date : "",
        end_date: props.dt_error === '' && props.start_date && props.end_date ? props.end_date : ""
      }
    });
  }

  const handleDragLeave = (event) => {
    event.preventDefault();

    // Update the state
    setUploadState({
      ...uploadState,
      isDragActive: false
    });
  }

  const uploadFile = (data, headers) => {
    postAxiosCall('/upload-rfp', data, headers)
        .then((response) => {
          if (response.data.url) {
            setUploadState((prevState) => ({
              ...prevState,
              uploadPercent: 60,
              preSignedUrl: response.data.url,
              uniqueFileName: data.key,
              fileWithUniqueName: data.fileWithUniqueName,
            }));
          }
        })
        .catch((error) => {
          setUploadState((prevState) => ({
            ...prevState,
            showError: error?.response?.status !== 401 && error?.response?.status > 0,
            invalidToken: error?.response?.status === 401,
            uploadPercent: 2,
            status: 'rejected',
          }));
        });
  };

  const uploadToPreSignedUrl = (preSignedUrl, fileWithUniqueName, headers) => {
    putAxiosCall(preSignedUrl, fileWithUniqueName, headers)
        .then(() => {
          setUploadState((prevState) => ({
            ...prevState,
            uploadPercent: 100,
            preSignedUrl: null,
            status: 'resolved',
          }));
        })
        .catch(() => {
          setUploadState((prevState) => ({
            ...prevState,
            preSignedUrl: null,
            status: 'rejected',
          }));
        });
  };

  useEffect(() => {
    const currentFile = uploadState.agendaFile?.[0];

    if (uploadState.status === 'pending' && !uploadState.preSignedUrl && currentFile) {
      setUploadState((prevState) => ({
        ...prevState,
        uploadPercent: 30,
        originalFile: currentFile,
      }));

      const uniqueFileName = generateUniqueFileName(currentFile, userRecords.userName);
      const fileWithUniqueName = generateFileWithUniqueName(currentFile);
      const data = {
        key: uniqueFileName,
        filetype: getMimeType(currentFile),
        fileWithUniqueName,
      };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: uploadState.apiToken,
      };

      uploadFile(data, headers);
    } else if (uploadState.preSignedUrl) {
      const preSignedUrlHeaders = {
        'Content-Type': `multipart/form-data, ${getMimeType(currentFile)}`,
      };

      uploadToPreSignedUrl(uploadState.preSignedUrl, uploadState.fileWithUniqueName, preSignedUrlHeaders);
    }
  }, [
    uploadState.status,
    uploadState.preSignedUrl,
    uploadState.agendaFile,
    uploadState.apiToken,
    clearUserSession,
    userRecords,
  ]);


  return (
      <>
        <div
            className={`dropzone ${uploadState.isDragActive ? 'dropzone-highlight' : ''}`}
            onDragOver={handleDragOver}
            data-testid='dragdrop-field'
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
        >
          <h5 className="customFileUploadFontColor" style={{ fontWeight: 'bold' }}>Select .XLSX or .PDF or .DOCX or .MSG to Upload</h5>
          <p>
            Click here to select the RFP file or click select RFP below to browse for the file. <br />
            You can also drag and drop your RFP file here to generate the agenda.
          </p>

          <button onClick={handleDivClick} className='selectrfp'>Select RFP</button>
          <input
              type="file"
              accept=".xlsx,application/pdf,application/vnd.ms-outlook,.msg,.docx"
              onChange={handleFileOnClick}
              data-testid='input-field'
              hidden
              ref={inputRef}
          />
        </div>
        <br />
        <div className="container text-center txt-left">
          {uploadState.agendaFilesNotValid?.[0] && (
              <div>
                <h6 className="txt-left" style={{ color: 'red' }}>
                  {uploadState.agendaFilesNotValid[0].name} is not a valid file &nbsp; <FontAwesomeIcon size={"sm"} icon={faFileCircleExclamation} />
                </h6>
                <div className="progress"
                     style={{ height: '5px', stroke: 'gray', storkeWidth: '9px' }}>
                  <div className="progress-bar" role="progressbar"
                       style={{ width: `${uploadState.uploadPercent}%`, background: 'gray' }}
                       aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>

          )}
          {uploadState.agendaFile?.[0] && (
              <div>

                {uploadState.uploadPercent === 100 && (
                    //<h5 style={{fontWeight: 'bold'}} className='txt-left upload-header customFileUploadFontColor'>{agendaFile[0].name} uploaded.</h5>
                    <h6 className="txt-left"
                        style={{ color: '#14AF02' }}>File <i>{uploadState.agendaFile[0].name}</i> uploaded.
                    </h6>
                )}

                {uploadState.uploadPercent < 100 && uploadState.uploadPercent > 1 && !uploadState.showError && (
                    <h6 className="txt-left"
                        style={{ color: '#0013FF' }}>Uploading <i>{uploadState.agendaFile[0].name}</i>.
                    </h6>
                )}

                {uploadState.agendaFile?.[0] && !uploadState.showError && uploadState.uploadPercent <= 1 && (
                    <h6 className="txt-left">Click below to
                      upload {uploadState.agendaFile[0].name}.</h6>
                )}

                {uploadState.invalidToken && (
                    <h6 className="txt-left" style={{ color: 'red' }}>Login token is either invalid or expired, please navigate to <Link to="/login">login</Link> page.</h6>
                )}


                {uploadState.showError && (
                    <h6 className="txt-left" style={{ color: 'red' }}>Failed to upload, please select the RFP file again.</h6>
                )}

                <div className="progress"
                     style={{ height: '5px', stroke: 'gray', storkeWidth: '9px' }}>
                  <div className="progress-bar" role="progressbar"
                       style={{ width: `${uploadState.uploadPercent}%`, background: 'gray' }}
                       aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                  </div>
                </div>
              </div>
          )}
          <div className="btn-right">
            {/* <button type="button" style={{ borderRadius: '84px' }}
            disabled={uploadState.status === 'pending' || uploadState.status === 'resolved' ||
              !(uploadState.agendaFile && uploadState.agendaFile[0])}
            onClick={handleUpload}
            className="btn  btn-outline-dark btn-lg btn-light">Upload File
          </button> */}
            <button type="button" style={{ borderRadius: '84px' }}
                    disabled={(uploadState.status !== 'resolved' || props.dt_error !== '')}
                    onClick={handleGenerateAgenda}
                    data-testid='gen-field'
                    className="btn  btn-outline-dark btn-lg btn-light">Generate
            </button>
          </div>
        </div>
      </>
  );
};

export default DragDropFiles;