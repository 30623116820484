import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import {
    functionTypes, setUpStyles,
    getFileNameWithoutExtension,
    isValidAgGridData,
    generateNewIdFromGrid,
    updateEventDate,
    updateEventDayNumber,
    datePickerFormat,
    modifyRowData, getDayByDate, validateEditPopup, handleEditRecords, addDefaultGridValue
} from '../utils/common-utils';

import _ from 'lodash';
import { gridSettings } from "../utils/grid-settings"
import { useInterval } from "../hooks/UseInterval";
import { fetchData, postAxiosCall } from "../api/axiosConfig"
import AuthContext from "../context/AuthContext";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCopy, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";
import Select from 'react-select';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import BulkEditDialog from './BulkEditDialog';

const Grids = () => {
    const [totalRows, setTotalRows] = useState(0);
    const timeoutRef = useRef(null);
    const gridRef = useRef(null);
    const [isCopyRowButtonDisabled, setIsCopyRowButtonDisabled] = useState(true);
    const [isDeleteRowButtonDisabled, setIsDeleteRowButtonDisabled] = useState(true);
    const [isEditRowButtonDisabled, setIsEditRowButtonDisabled] = useState(true);
    const navigate = useNavigate();
    const { isSessionExpired, userRecords, clearUserSession } = useContext(AuthContext);

    const location = useLocation();
    const gridData = addDefaultGridValue((location?.state?.gridData?.events ? location?.state?.gridData?.events : location?.state?.gridData), functionTypes, setUpStyles);
    const originalFile = location?.state?.originalFile;

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%', textAlign: "left !important" }), []);
    const gridStyle = useMemo(() => ({ height: '500px', width: "100%", border: '0px', textAlign: "left !important" }), []);
    const [isModified, setModify] = useState(false);
    const [downloadMessage, setDownloadMessage] = useState("")

    let dateRef = useRef()
    let startTimeRef = useRef()
    let endTimeRef = useRef()
    let functionTypeRef = useRef()
    let peopleCountRef = useRef()
    let dateBoxRef = useRef(null)
    const [editData, setEditData] = useState({
        date: '',
        startTime: '',
        endTime: '',
        functionType: '',
        postage: '',
        setupStyle: '',
        peopleCount: '',
        comments: ''
    });

    const autoSizeStrategy = useMemo(() => {
        return {
            type: 'fitGridWidth', // fitGridWidth fitCellContents
        };
    }, []);

    const [rowData, setRowData] = useState(gridData);
    const [columnDefs] = useState(gridSettings);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            resizable: true,
            editable: true,
            // minWidth: 150,
            floatingFilter: true
        };
    }, []);
    const onCellValueChanged = useCallback((params) => {
        const dataRowIndex = rowData.findIndex(item => item.id === params.data.id);
        if (dataRowIndex < 0) return;

        // Create a deep copy of rowData to avoid direct mutations
        let updatedRowData = _.cloneDeep(rowData);
        let fieldsToFlash = [];

        // Map of field names to update functions
        const updateFunctions = {
            date: (newValue, oldValue) => {
                if (newValue === '' || newValue === null) {
                    // Revert to old value if Date is empty
                    params.node.setDataValue(params.colDef.field, oldValue);
                    return updatedRowData;
                }
                fieldsToFlash.push('date', 'dayNumber');
                return updateEventDate(updatedRowData, params.data.id, datePickerFormat(newValue));

            },
            dayNumber: (newValue, oldValue) => {
                if (newValue === '' || newValue === null) {
                    // Revert to old value if Day # is empty
                    params.node.setDataValue(params.colDef.field, oldValue);
                    return updatedRowData;
                }
                fieldsToFlash.push('dayNumber', 'date');
                return updateEventDayNumber(updatedRowData, params.data.id, newValue);
            },
        };

        // Check if the field has a corresponding update function
        if (updateFunctions[params.colDef.field]) {
            updatedRowData = updateFunctions[params.colDef.field](params.newValue, params.oldValue);
        } else {
            // For fields that don't have a specific update function
            const { dataRowIndex, updatedRowData } = modifyRowData(params, rowData)
            updatedRowData[dataRowIndex] = { ...updatedRowData[dataRowIndex], [params.colDef.field]: params.newValue };
            fieldsToFlash.push(params.colDef.field);

            if (dataRowIndex >= 0) {
                setRowData(updatedRowData);
                setModify(true);
            }
        }

        setRowData(updatedRowData);  // Update the state with the new rowData array
        setModify(true);

        // Flash the updated cells
        params.api.flashCells({
            rowNodes: [params.node],
            columns: fieldsToFlash,
            flashDelay: 100,    // Optional: adjust timing as needed
            flashDuration: 1000 // Optional: adjust timing as needed
        });
    }, [rowData, setRowData, setModify]);

    const [rdsData, setRdsData] = useState({});
    const [apiToken, setApiToken] = useState('');
    const [rdsUpdateStatus, setRdsUpdateStatus] = useState('idle');
    const [invalidToken, setInvalidToken] = useState(false);
    const [gridToExcelConversionStatus, setGridToExcelConversionStatus] = useState('idle');
    const [countGridToExcelCall, setCountGridToExcelCall] = useState(0);
    const [isUseIntervalRunning, setIsUseIntervalRunning] = useState(false);
    const [errorMessageGridData, setErrorMessageGridData] = useState("");
    const redirectUpload = () => {
        navigate("/upload");
    }

    // Run Only Once - when the component Did Mount
    useEffect(() => {
        const accessToken = (userRecords?.['accessToken'] ? userRecords['accessToken'] : "")
        setInvalidToken(isSessionExpired(accessToken));
        setApiToken(accessToken || null);
    }, [isSessionExpired, userRecords])

    useEffect(() => {
        if ((rdsUpdateStatus === 'initiated' || rdsUpdateStatus === 'pending')) {
            const saveToRds = async () => {
                const saveToRdsHeaders = {
                    'Content-Type': "application/json",
                    'Authorization': apiToken
                }

                return postAxiosCall('/update', rdsData, saveToRdsHeaders);
            }

            saveToRds().then((response) => {
                if (response.data) {
                    setRdsUpdateStatus("success");
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    clearUserSession()
                    setInvalidToken(true);
                    setRdsUpdateStatus('failed');

                } else {
                    console.error('error when calling the save-update-rds call ', error);
                    setRdsUpdateStatus('failed');
                }
            });
        }
    }, [apiToken, rdsData, rdsUpdateStatus, clearUserSession]);

    useEffect(() => {
        let timerId = null;

        if (downloadMessage !== "") {
            timerId = setTimeout(() => setDownloadMessage(""), 7000);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, [downloadMessage])

    useEffect(() => {
        if ((gridToExcelConversionStatus === 'initiated' || gridToExcelConversionStatus === 'pending')) {
            setDownloadMessage("Please wait...Download in Progress");
            const finalFileName = getFileNameWithoutExtension({ name: location?.state?.inputFileName }) + '.xlsx';

            const convertJsonToExcel = async () => {
                const jsonToExcelHeaders = {
                    'Content-Type': "application/json",
                    'Authorization': apiToken
                }

                const jsonToExcelData = {
                    data: rowData,
                    finalFileName: finalFileName
                }

                return postAxiosCall('/json-to-excel', jsonToExcelData, jsonToExcelHeaders);
            }

            convertJsonToExcel().then((response) => {
                if (response?.data?.url) {
                    window.open(response.data.url, "_blank")
                    setDownloadMessage("File downloaded successfully.");
                    setGridToExcelConversionStatus('success');
                }
            }).catch((error) => {
                console.log(`error when calling the convert json to excel call`, error);
                if (error.response.status === 401) {
                    clearUserSession();
                    setGridToExcelConversionStatus('Invalid Token or Expired Token');
                    setDownloadMessage("Unable to download excel file.");
                    setInvalidToken(true)
                } else if (error?.response?.status === 504) {
                    setGridToExcelConversionStatus('timeout');
                    setDownloadMessage("Checking if File Exists...");
                    setIsUseIntervalRunning(true);
                } else {
                    setDownloadMessage("Unable to download excel file.")
                    console.error(error, 'invalid-token', invalidToken);
                    setGridToExcelConversionStatus('failed');
                }
            });

        }

    }, [
        apiToken, rowData, gridToExcelConversionStatus, location?.state?.inputFileName,
        clearUserSession, invalidToken
    ]);

    useEffect(() => {
        return () => {
            // Clear the timeout if the component is unmounted
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null; // Reset the ref after clearing the timeout
            }
        };

    }, [])

    const updateTotalRowCount = () => {
        if (gridRef?.current?.api) {
            const selectedRows = gridRef.current.api.getSelectedRows();
            if (selectedRows?.length > 0) {
                setTotalRows(selectedRows?.length);
            } else {
                setTotalRows(gridRef.current.api.getDisplayedRowCount());
            }
        }
    };

    const getExcelOutputFile = async () => {
        const retrieveCallParams = {
            fileName: getFileNameWithoutExtension({ name: location?.state?.inputFileName }) + '.xlsx',
            isRequestForFinalFile: true
        }
        const retrieveCallHeaders = {
            'Content-Type': "application/json",
            'Authorization': apiToken
        }
        return fetchData('/retrieve', retrieveCallParams, retrieveCallHeaders);
    }

    useInterval(() => {
        getExcelOutputFile().then((response) => {
            console.log('response', response);
            // download the file in a new window
            window.open(response.data, "_blank");

            // set the status of Grid to Excel Conversion as success
            setGridToExcelConversionStatus('success');

            // set the download message
            setDownloadMessage("File downloaded successfully.")

            // stop the pooling
            setIsUseIntervalRunning(false);
        }).catch((error) => {
            // Track how many calls went out
            setCountGridToExcelCall((countGridToExcelCall) => countGridToExcelCall + 1);
            console.log('inside useInterval me', gridToExcelConversionStatus, 'count here ', countGridToExcelCall);
            if (error?.response?.status === 401) {
                setGridToExcelConversionStatus('Invalid Token or Expired Token');
                setDownloadMessage("Unable to download excel file.");
                setInvalidToken(true)
                setIsUseIntervalRunning(false);
            } else if (countGridToExcelCall <= 10 && error?.response?.status !== 404) {
                // continue the pooling
                setIsUseIntervalRunning(true);
                setDownloadMessage("Please wait...Checking if File was already created");
            } else {
                // stop the pooling
                setIsUseIntervalRunning(false);
                setGridToExcelConversionStatus('failed');
                setDownloadMessage("Unable to download excel file. File not found in s3 bucket");
            }
        });
    }, (isUseIntervalRunning ? 15000 : null));

    const downloadFile = () => {
        const isoTimestamp = moment().toISOString()
        const data = {
            "username": (userRecords?.['userName'] ? userRecords['userName'] : ""),
            "filename": location?.state?.inputFileName,
            "date_time": isoTimestamp,
            "ismodified": isModified
        }
        setRdsData(data);
        setRdsUpdateStatus("initiated");
        setGridToExcelConversionStatus("initiated");
    }

    const feedBack = () => {
        const feedBackUrl = "https://forms.office.com/r/hy3z9f8ZNE";
        window.open(feedBackUrl, '_blank', 'noreferrer');
    }

    const onGridReady = (params) => {
        gridRef.current.api = params.api;
        gridRef.current.columnApi = params.columnApi

        params.api.sizeColumnsToFit();
        const gridNodes = gridRef.current.api.getSelectedNodes()
        setIsCopyRowButtonDisabled(gridNodes?.length <= 0);

        if (isValidAgGridData(rowData)) {
        //if (rowData && rowData.length > 0) {
            setErrorMessageGridData("");
        } else {
            setErrorMessageGridData("The generated file is missing required information. " +
                "Please complete all required fields before uploading your data to the S&C System.");
        }
    }

    const onSelectionChanged = (params) => {
        const selectedNodes = params.api.getSelectedNodes()
        setIsEditRowButtonDisabled(selectedNodes?.length <= 0);
        setIsCopyRowButtonDisabled(selectedNodes?.length <= 0);
        setIsDeleteRowButtonDisabled(selectedNodes?.length <= 0);
        updateTotalRowCount();
    }

    const handleDateChange = useCallback((event) => {
        console.log('Event received:', event); // Add this to see incoming value
        let selectedDate = getDayByDate(event)
        console.log('Selected date:', selectedDate);
        if (selectedDate) {
            console.log(`inside if condition `, selectedDate);
            dateBoxRef.current = selectedDate
            setEditData(prevState => {
                const newState = { ...prevState, date: selectedDate };
                console.log('New editData state:', newState);
                return newState;
            });

            // dateBoxRef.current = selectedDate

            dateRef.current.innerHTML = ""
        }
    }, [])


    const handleEditChange = useCallback((event) => {
        let { valObj, error, name, value } = validateEditPopup(event, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef)
        editData[name] = value;

        setEditData(prevState => ({
            ...prevState,
            name: value
        }));
        if (valObj) {
             valObj.current.innerHTML = value.trim() === "" ? error : ""
        }
    }, [editData]);

    const resetData = useCallback((event) => {
        setEditData({ date: '', startTime: '', endTime: '', functionType: '', postage: '', setupStyle: '', peopleCount: '', comments: '' })
    }, [editData]);

    useEffect(()=> {
        console.log('side effect', editData);
    }, [editData])


    const handleChange = useCallback((callback, nodes) => {
        console.log('EditData after submit:', editData);
        console.log('dateBoxRef', dateBoxRef.current);

        /*
        TODO - React-confirm-alert renders the modal outside of the React tree,
         and React’s state updates are not properly propagated.
         As a result, directly updating/mutating the state like this.
         Need to refactor the modal component and fix this issue
        */
        editData.date = dateBoxRef.current;

        let itemsToUpdate = [];
        let copyRowData = _.cloneDeep(rowData);
        let isError = false
        let isBlank = true
        for (const key in editData) isBlank = editData[key] !== "" ? false : isBlank

        if(!isBlank) {
            let {errorStat, copyRecordData,  recToUpdate} = handleEditRecords(gridRef, nodes, editData, copyRowData, {dateRef, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef})
            isError = errorStat
            itemsToUpdate = recToUpdate
            copyRowData = copyRecordData
        }

        gridRef.current.api.applyTransaction({ update: itemsToUpdate });
        gridRef.current.api.deselectAll();
        gridRef.current.api.flashCells({
            rowNodes: nodes,
            flashDelay: 100,
            flashDuration: 500,
        });

        setRowData(copyRowData)

        if (!isError) {
            callback()
        }
    }, [editData, rowData]);

    const showBulkEditDialog = useCallback((nodes) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                console.log('Current editData in confirmAlert:', editData);
                return (
                    <BulkEditDialog
                        editData={editData}
                        handleDateChange={handleDateChange}
                        handleEditChange={handleEditChange}
                        handleSubmit={(callback) => handleChange(callback, nodes)}
                        resetData={resetData}
                        onClose={onClose}
                        dateRef={dateRef}
                        startTimeRef={startTimeRef}
                        endTimeRef={endTimeRef}
                        functionTypeRef={functionTypeRef}
                        peopleCountRef={peopleCountRef}
                    />
                );
            },
            closeOnClickOutside: false,
            transparent: true
        });
    }, [editData, handleChange, handleDateChange, handleEditChange, resetData]);

    const onEditRow = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const itemsToEdit = selectedNodes.map(node => node.data);

        // Check if there are selected rows to delete
        if (itemsToEdit.length === 0) {
            return;
        }

        showBulkEditDialog(selectedNodes)
    }

    const onDeleteRow = () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const itemsToDelete = selectedNodes.map(node => node.data);

        // Check if there are selected rows to delete
        if (itemsToDelete.length === 0) {
            // Optionally, notify the user that no rows are selected for deletion
            return;
        }

        confirmAlert({
            message: 'Are you sure to delete the selected ' + (itemsToDelete.length > 1 ? itemsToDelete.length : '') + ' record' + (itemsToDelete.length > 1 ? 's' : '') + '.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        gridRef.current.api.flashCells({
                            rowNodes: selectedNodes,
                            flashDelay: 100, // milliseconds before the flash starts
                            flashDuration: 500, // milliseconds the flash lasts
                        });
                        timeoutRef.current = setTimeout(() => {
                            gridRef.current.api.applyTransactionAsync({ remove: itemsToDelete }, (res) => {
                                if (res.remove && res.remove.length > 0) {
                                    // Update the React state to reflect the deletion
                                    setRowData(prevRowData => {
                                        return prevRowData.filter(row => {
                                            return !itemsToDelete.map(item => item.id).includes(row.id)
                                        });
                                    });
                                }
                            });

                            gridRef.current.api.deselectAll();
                            setIsDeleteRowButtonDisabled(true)

                        }, 600)

                    }
                },
                { label: 'No' }
            ]
        });
    }

    const updateReactStateFromGrid = () => {
        const rowData = [];
        gridRef.current.api.forEachNode(node => rowData.push(node.data));
        setRowData(rowData);
    }

    const onAddRow = () => {
        const newId = generateNewIdFromGrid(rowData);

        const newItem = {
            "id": newId,
            "date": "",
            "day": "",
            "startTime": "",
            "endTime": "",
            "functionType": "",
            "setupStyle": "",
            "peopleCount": "",
            "comments": "",
            "signage": ""
        }

        const callback = (res) => {
            if (res.add) {
                res.add.forEach(rowNode => {
                    gridRef.current.api.flashCells({ rowNodes: [rowNode] });
                });

                gridRef.current.api.refreshCells({ force: true });

                timeoutRef.current = setTimeout(() => {
                    updateReactStateFromGrid();
                }, 600)
            }
        };

        // Clear any active filters to ensure the new row is visible
        gridRef.current.api.setFilterModel(null);

        gridRef.current.api.applyTransactionAsync({ add: [newItem], addIndex: 0 }, callback);
    }

    const onCopyRow = async () => {
        const selectedNodes = gridRef.current.api.getSelectedNodes().sort((a, b) => a.rowIndex - b.rowIndex);
        const insertPromises = []; // To hold promises for each insert operation
        let adjustment = 0; // Adjustment for the insertion index due to previous inserts

        const allNodes = [];
        // Get all nodes including filtered out
        gridRef.current.api.forEachNode(node => allNodes.push(node));

        selectedNodes.forEach(node => {
            const newId = generateNewIdFromGrid(rowData);
            const copy = { ...node.data, id: newId }; // Copy the data and assign a new unique ID

            // Calculate the adjusted index for the insertion
            // Find the unfiltered index of the node
            const unfilteredIndex = allNodes.findIndex(n => n.data.id === node.data.id);
            const insertIndex = unfilteredIndex + 1 + adjustment;

            // Create a promise for each row insertion and add it to the array
            const insertPromise = new Promise(resolve => {
                gridRef.current.api.applyTransactionAsync({ add: [copy], addIndex: insertIndex }, () => {
                    resolve({ copy, insertIndex }); // Resolve with the copied data and its insert index
                });
            });
            insertPromises.push(insertPromise);
            adjustment++; // Increment adjustment for the next row
        });

        // Wait for all insert operations to complete
        const insertedRows = await Promise.all(insertPromises);

        insertedRows.forEach(({ copy, insertIndex }) => {
            console.log(`id here`, copy, insertIndex);
            // Use a slight delay to ensure the grid has processed the new rows
            setTimeout(() => {
                const rowNode = gridRef.current.api.getRowNode(insertIndex);
                if (rowNode) {
                    gridRef.current.api.flashCells({
                        rowNodes: [rowNode],
                        flashDelay: 100, // Optional: adjust timing as needed
                        flashDuration: 1000, // Optional: adjust timing as needed
                    });
                }
            }, 100); // Adjust this delay as necessary
        });

        // Once all rows are inserted, update the React state
        setRowData(prevRowData => {
            const updatedRowData = [...prevRowData];
            // Insert the new rows into the state based on their insert index
            insertedRows.forEach(({ copy, insertIndex }) => {
                updatedRowData.splice(insertIndex, 0, copy); // Insert the copied row at the calculated index
            });
            console.log(`updatedRowData`, updatedRowData);
            return updatedRowData;
        });

        // Deselect rows and reset UI state as needed
        gridRef.current.api.deselectAll();
        setIsCopyRowButtonDisabled(true);
    }

    const getRowId = useCallback((params) => params.data.id, []);

    return (
        <>
            <div className="container text-center" style={containerStyle}>
                <br />
                <div className="container text-center" style={{ padding: "0px" }}>
                    <div className="row" style={{ width: "auto", paddingRight: "0px", float: "auto" }}>
                        {errorMessageGridData && (
                            <div className="text-danger fs-6" style={{
                                float: "left",
                                textAlign: "left",
                                paddingTop: "21px"
                            }}>{errorMessageGridData}</div>
                        )}
                        {invalidToken && (
                            <div style={{
                                float: "left",
                                width: "600px",
                                textAlign: "right",
                                paddingTop: "21px",
                                fontWeight: "bold"
                            }}>Login token is either invalid or expired, please navigate to <Link
                                to="/login">login</Link> page.</div>
                        )}
                        <div style={{
                            float: "left",
                            textAlign: "right",
                            paddingTop: "21px",
                            fontWeight: "bold"
                        }}>{downloadMessage}</div>
                        <div className="col"
                            style={{ textAlign: 'right', width: "181px", float: "right", padding: "0px" }}>
                            <button type="button" className='btn btn-outline-dark btn-lg btn-light'
                                style={{ borderRadius: '84px' }}
                                onClick={redirectUpload}>Start New Import
                            </button>
                            <button type="button" className='btn btn-outline-dark btn-lg btn-light'
                                style={{ borderRadius: '84px' }} onClick={downloadFile}
                                disabled={gridToExcelConversionStatus === 'pending'
                                    || gridToExcelConversionStatus === 'timeout'
                                    || gridToExcelConversionStatus === 'initiated'}>Download
                            </button>
                        </div>
                    </div>
                </div>
                <br />

                <div className="divTable" style={{ width: "100%" }}>
                    <div className="headRow">
                        <div className="divMainCellHeader">File Name</div>
                        <div className="divMainCellHeader">&nbsp;</div>
                    </div>
                    <div className="divRow">
                        <div className="divMainCell">{originalFile?.name}</div>
                        <div className="divMainCell">&nbsp;</div>
                    </div>
                </div>

                <div className="d-flex justify-content-end mb-0 pb-0">
                    <div style={{ width: "10%", float: "left" }}>
                        <div className="divRow" style={{ float: "left", marginLeft: "-12px" }}>
                            <div className="d-flex justify-content-end mb-0 pb-0">
                                <button disabled={isEditRowButtonDisabled}
                                    onClick={onEditRow}
                                    className="btn btn-outline-dark btn-lg btn-light btn-sm">
                                    <FontAwesomeIcon size={"sm"} icon={faEdit} /> Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "90%", float: "right" }}>
                        <div className="divRow" style={{ float: "right" }}>
                            <div className="d-flex justify-content-end mb-0 pb-0">
                                <button disabled={isDeleteRowButtonDisabled}
                                    onClick={onDeleteRow}
                                    className="btn btn-outline-dark btn-lg btn-light btn-sm">
                                    <FontAwesomeIcon size={"sm"} icon={faTrashCan} /> Delete
                                </button>
                            </div>
                        </div>
                        <div className="divRow" style={{ float: "right" }}>
                            <div className="d-flex justify-content-end mb-0 pb-0">
                                <button disabled={isCopyRowButtonDisabled}
                                    onClick={onCopyRow}
                                    className="btn btn-outline-dark btn-lg btn-light btn-sm">
                                    <FontAwesomeIcon size={"sm"} icon={faCopy} /> Copy
                                </button>
                            </div>
                        </div>
                        <div className="divRow" style={{ float: "right" }}>
                            <div className="d-flex justify-content-end mb-0 pb-0">
                                <button disabled={false}
                                    onClick={onAddRow}
                                    className="btn btn-outline-dark btn-lg btn-light btn-sm">
                                    <FontAwesomeIcon size={"sm"} icon={faAdd} /> Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={gridStyle} className="ag-theme-balham">
                    <AgGridReact
                        // domLayout={'autoHeight'}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        columnHoverHighlight={true}
                        stopEditingWhenCellsLoseFocus={true}
                        onCellValueChanged={onCellValueChanged}
                        autoSizeStrategy={autoSizeStrategy}
                        enableColResize={true}
                        rowHeight={50}
                        onGridReady={onGridReady}
                        ref={gridRef}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection="multiple"
                        singleClickEdit={true}
                        suppressRowClickSelection={true}
                        onModelUpdated={updateTotalRowCount}
                        onFilterChanged={updateTotalRowCount}
                        getRowId={getRowId}
                    />
                </div>
                <div className="text-end mt-2">
                    Total Row Count: <span id="rowCount">{totalRows}</span>
                </div>
                <div className="container text-center" style={{ padding: "0px" }}>
                    <div className="row" style={{ width: "auto", paddingRight: "0px", float: "auto" }}>
                        <div className="col"
                            style={{ textAlign: 'right', width: "181px", float: "right", padding: "0px" }}>
                            <button type="button" className='btn btn-outline-dark btn-lg btn-light'
                                style={{ borderRadius: '84px' }} onClick={feedBack}>Feedback
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-6 col">
                            <small style={{ fontStyle: "italic" }}>Provide feedback on this output, including any issues you've identified or general suggestions</small>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ clear: "both" }}></div>
        </>
    );
}

export default Grids;
