import {
    isValidDate,
    isValidTime,
    isValidSetupStyle,
    isValidFunctionType,
    getFormattedTime
} from "../utils/common-utils";

export const CustomAgGridValidationRenderer = (props) => {
    const columnConfig = {
        functionType: {
            validate: (value) => isValidFunctionType(props.dataToValidate, value),
            format: (value) => value, // No formatting needed, return as is
        },
        setUpStyle: {
            validate: (value) => isValidSetupStyle(props.dataToValidate, value),
            format: (value) => value, // No formatting needed, return as is
        },
        Date: {
            validate: isValidDate,
            format: (value) => value, // No formatting needed, return as is
        },
        startTime: {
            validate: (value) => isValidTime(value.trim()),
            format: (value) => getFormattedTime(value), // Format time
        },
        endTime: {
            validate: (value) => isValidTime(value.trim()),
            format: (value) => getFormattedTime(value) // Format time
        },
    };

    // Determine the current column's config
    const currentColumnConfig = columnConfig[props.columnName];

    // Validate the value using the appropriate function
    let isValid = currentColumnConfig ? currentColumnConfig.validate(props.value) : false;

    // Format the value if valid, otherwise keep the original value
    let displayValue = isValid && currentColumnConfig ? currentColumnConfig.format(props.value) : props.value;

    const cellClass = isValid ? '' : 'ag-cell-invalid-field';

    return (
        <>
            {isValid ? (
                <p>
                    <span>{displayValue}</span>
                </p>
            ) : (
                <>
                    <div className={cellClass}>
                        <span className={cellClass}>{displayValue}</span>
                    </div>

                    <p>
                        <span className={'text-danger'} style={{fontSize: "xx-small"}}>{props.validationMessage}</span>
                    </p>
                </>
            )}
        </>
    );
};
