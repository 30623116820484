import moment from "moment";
import * as _ from "lodash";

export const getFileExtension = (file) => {
    // Check if the file has an extension
    const lastDotIndex = file.name.lastIndexOf('.');
    if (lastDotIndex === -1) return ''; // Return an empty string if no extension is found

    // Extract the extension and convert it to lowercase
    return file.name.substring(lastDotIndex).toLowerCase();
};

export const changeFileExtension = (file, extensionToAdd = 'json') => {
    let fileNameWithoutExtension = getFileNameWithoutExtension(file);
    return fileNameWithoutExtension + '.' + extensionToAdd;

};
export const getFileNameWithoutExtension = (file) => {
    let index = file?.name?.lastIndexOf('.');
    return file?.name?.slice(0, index);
};

export const getUserNamePartOnly = (userName) => {

    if (!userName) {
        return 'noUserName';
    }
    return userName
};

export const getUnixTimeStampInSeconds = () => {
    // Get current Unix timestamp in milliseconds
    const timestampMillis = Date.now();

    // Convert milliseconds to seconds
    return Math.floor(timestampMillis / 1000);

};

export const generateUniqueFileName = (file, username) => {
    let uniqueFileName = '';

    const emailPartOnly = getUserNamePartOnly(username);
    const fileNameWithoutExtension = getFileNameWithoutExtension(file);
    const fileExtension = getFileExtension(file);
    const timeInSeconds = getUnixTimeStampInSeconds();

    uniqueFileName = emailPartOnly + '-' + fileNameWithoutExtension + '-' +
        timeInSeconds + fileExtension;
    return uniqueFileName;
};

export const generateFileWithUniqueName = (file) => {
    const uniqueFileName = generateUniqueFileName(file);

    const blob = file.slice(0, file.size, file.type);
    return new File([blob], uniqueFileName, { type: file.type });
};

export const getValidAndInValidFileList = (fileList, allowedFileExtensions) => {
    let acceptableFileExtensions = allowedFileExtensions || ['.pdf', '.docx', '.doc', '.xls', '.xlsx', '.msg'];
    const validFilesForUpload = [];
    const filesNotValidForUpload = [];

    fileList.forEach(singleFile => {
        const fileExtension = getFileExtension(singleFile);

        if (acceptableFileExtensions.indexOf(fileExtension) >= 0) {
            validFilesForUpload.push(singleFile);
        } else {
            filesNotValidForUpload.push(singleFile);
        }
    });

    return {
        validFileList: validFilesForUpload,
        inValidFileList: filesNotValidForUpload,
    };
};

export const functionTypes = [
    '24 Hour Hold',
    'Board Meeting',
    'Box Lunch',
    'Breakfast',
    'Breakfast Buffet',
    'Breakout',
    'Brunch',
    'Ceremony',
    'Changing Room',
    'Coat Check',
    'Cocktail Reception',
    'Coffee Break',
    'Continental Breakfast',
    'Continuous Break',
    'Dance',
    'Dinner',
    'Dinner Buffet',
    'Exhibits',
    'General Session',
    'Holding Room',
    'Hospitality Room',
    'In-house Meeting',
    'Interview',
    'Lunch',
    'Lunch Buffet',
    'Meal on Own',
    'Meeting',
    'Menu Tasting',
    'No Agenda Hold',
    'Off Site',
    'Office',
    'Reception',
    'Recreation',
    'Registration',
    'Rehearsal',
    'Room Ready',
    'Set Up',
    'Speaker Room',
    'Special',
    'Storage',
    'Teardown',
    'Trade Show'
];
export const setUpStyles = [
    'Chevron Schoolroom',
    'Chevron Theatre',
    'Cocktail Rounds',
    'Conference',
    'Conference 2 per 6',
    'Conference 3 per 8',
    'Crescent Rounds',
    'Exhibits',
    'Hollow Square',
    'Hollow Square 2 per 6',
    'Hollow Square 3 per 8',
    'Lounge',
    'Off Site',
    'Oval Conference',
    'Registration',
    'Rounds of 10',
    'Rounds of 12',
    'Rounds of 6',
    'Rounds of 8',
    'Schoolroom',
    'Schoolroom 2 per 6',
    'Schoolroom 3 per 8',
    'Special',
    'Storage',
    'Theatre',
    'U-Shape',
    'U-Shape 2 per 6',
    'U-Shape 3 per 8',
]

export const setGenAISalesAgendaLocalStorage = (loginResponseObject) => {
    console.log('util localstorage', loginResponseObject)
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_INITIA || 'GenAI_Sales_Agenda_User_Initial', loginResponseObject?.userInitial
    );
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_ACCESS_TOKEN || 'GenAI_Sales_Agenda_Access_token', loginResponseObject?.accessToken
    );
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_EXPIRES_AT || 'GenAI_Sales_Agenda_Expires_At', loginResponseObject?.expiresAt
    );
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_EXPIRES_IN || 'GenAI_Sales_Agenda_Expires_In', loginResponseObject?.expiresIn
    );
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_User_Name || 'GenAI_Sales_Agenda_User_Name', loginResponseObject?.userName
    );
    localStorage.setItem(
        process.env.REACT_APP_GENAI_SALES_AGENDA_TOKEN_ID || 'GenAI_Sales_Agenda_Token_ID', loginResponseObject?.token_id
    );
}

export const getGenAISalesAgendaLocalStorageValue = (key) => {
    return localStorage.getItem(key);
}

export const isValidDate = (dateString) => {
    return moment(dateString, 'MM/DD/YYYY', true).isValid();
}

export const isValidTime = (dateString) => {
    return moment(dateString, allPossibleTimeFormats, true).isValid();
}

export const isValidSetupStyle = (dataToValidate, value) => {
    // Even when setup style doesn't have a value - it is valid
    if (!value) {
        return true;
    }
    return _.includes(dataToValidate, value);
}

export const isValidFunctionType = (dataToValidate, value) => {
    return _.includes(dataToValidate, value);
}

export const getMimeType = (file) => {
    let mimeType = file.type; // This will be an empty string for .msg files
    let fileName = file.name;
    let fileExtension = fileName.split('.').pop().toLowerCase();

    if (fileExtension === 'msg') {
        mimeType = 'application/vnd.ms-outlook';
    }

    return mimeType;
}

export const isValidAgGridData = (rowData) => {
    for (const node of rowData) {
        if (!isValidDate(node.date) || !isValidTime(node.startTime) || !isValidTime(node.endTime)
            || !isValidSetupStyle(setUpStyles, node['setupStyle'])
            || !isValidFunctionType(functionTypes, node['functionType'])
            || _.isEmpty(node['peopleCount'])) {
            return false; // Return false on first error
        }
    }
    return true; // Return true if all nodes are valid
};


export const getFormattedTime = (dateString) => {
    return moment(dateString, allPossibleTimeFormats).format('hh:mm A');
}

export const allPossibleTimeFormats = [
    "hh:mm A",
    "h:mm A",
    "hha",
    "hhA",
    "ha",
    "hA",
    "h a",
    "h A",
    "h:mma",
    "h:mmA",
];

export const timeValueParser = (params) => {
    const isTimeValid = isValidTime(params.newValue);
    // Format the time to 'hh:mm A' format if valid, else return the original value
    return isTimeValid ? getFormattedTime(params.newValue) : params.newValue;
};

export const generateUniqueId = () => "uytvsdlie39ge"

export const addUniqueIdToEvents = (events) => {
    if (events && events.length > 0) {
        return events.map(event => ({
            ...event,
            id: _.uniqueId('event_')
        }));
    } else {
        return events || []
    }
}

export const generateNewIdFromGrid = (rowData) => {
    console.log(`rowData`, rowData);
    const maxIdSuffix = rowData.length > 0
        ? Math.max(...rowData.map(item => parseInt(item.id.replace('event_', ''), 10)))
        : 0;
    return `event_${maxIdSuffix + 1}`;
};

export const modifyRowData = (params, rowData) => {
    const dataRowIndex = rowData.findIndex(item => item.id === params.data.id);
    let updatedRowData = [];
    let dataVal = ""
    if (dataRowIndex >= 0) {
        dataVal = params.value
        if (params.colDef.field === "date") {
            if (params.value) {
                const d = moment(params.value, 'MM/DD/YYYY')
                const month = d.format('MM') + 1
                const day = d.format('DD');
                const dayname = d.format('dddd');
                const fullYear = d.format('YYYY');
                dataVal = `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}/${fullYear}`;
                rowData[dataRowIndex]['day'] = dayname
            }
            else {
                dataVal = params.oldValue
            }
        }

        // Create a new copy of the row with the updated value
        const updatedRow = { ...rowData[dataRowIndex], [params.colDef.field]: dataVal };

        // Create a new rowData array with the updated row
        updatedRowData = [
            ...rowData.slice(0, dataRowIndex),
            updatedRow,
            ...rowData.slice(dataRowIndex + 1)
        ];
    }

    return { dataRowIndex, updatedRowData }
}

export const assignEventDayNumbers = (events) => {
    if (events.length === 0) return [];

    const sortedEvents = events.map(event => ({
        ...event,
        momentDate: moment(event.date, 'MM/DD/YYYY')
    })).sort((a, b) => a.momentDate - b.momentDate);

    let currentDayNumber = 1;
    let previousDate = sortedEvents[0].momentDate;

    sortedEvents.forEach((event, index) => {
        if (index === 0) {
            event.dayNumber = currentDayNumber;
            event.day = event.momentDate.format('dddd'); // Update the day field
            return;
        }

        const daysGap = event.momentDate.diff(previousDate, 'days');
        currentDayNumber += daysGap;
        event.dayNumber = currentDayNumber;
        event.day = event.momentDate.format('dddd'); // Update the day field
        previousDate = event.momentDate;
    });

    return sortedEvents.map(event => {
        const { momentDate, ...rest } = event;
        return rest;
    });
};

export const updateEventDate = (events, eventId, newDate) => {
    const updatedEvents = events.map(event =>
        event.id === eventId ?
            {
                ...event,
                date: newDate,
                day: moment(newDate, 'MM/DD/YYYY').format('dddd')
            }
            : event
    );
    return assignEventDayNumbers(updatedEvents);
};

export const updateEventRecords = (events, eventId, newDate, records) => {
    const updatedEvents = events.map(event =>
        event.id === eventId ?
            {
                ...event,
                ...records,
                date: newDate,
                day: moment(newDate, 'MM/DD/YYYY').format('dddd')
            }
            : event
    );
    return assignEventDayNumbers(updatedEvents);
};

export const updateEventDayNumber = (events, eventId, newDayNumber) => {
    const baseDate = moment(events[0].date, 'MM/DD/YYYY');
    const newDate = baseDate.add(newDayNumber - 1, 'days').format('MM/DD/YYYY');

    const updatedEvents = events.map(event =>
        event.id === eventId ?
            {
                ...event,
                date: newDate,
                day: moment(newDate, 'MM/DD/YYYY').format('dddd')
            }
            : event
    );
    return assignEventDayNumbers(updatedEvents);
};

export const datePickerFormat = (rawDate) => {
    if (!rawDate) { return ""; }
    return moment(rawDate).format('MM/DD/YYYY');
};


export const getTimeValue = (time) => {
    return moment(time, ["HH:mm"]).format("h:mm A")
};

export const getDayByDate = (event) => {
    return event ? moment(event).format('MM/DD/YYYY') : "";
}

export const validateEditPopup = (event, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef) => {
    let valObj, error
    let { name, value } = event.name ? event : event.target;

    if (name === "startTime") {
        valObj = startTimeRef
        error = "Invalid start time (HH:MM AM or PM)"
        value = getTimeValue(value)
    }
    if (name === "endTime") {
        valObj = endTimeRef
        error = "Invalid end time (HH:MM AM or PM)"
        value = getTimeValue(value)
    }
    if (name === "functionType") {
        valObj = functionTypeRef
        error = "Please select function type"
    }
    if (name === "peopleCount") {
        valObj = peopleCountRef
        error = "Please enter valid expected people count"
        value = Number(value) > 0 ? value : ""
    }

    return { valObj, error, name, value }
}

export const changeDateFormat = (date, mode = 'YMD2MDY') => {
    if (!date) return ""
    try {
        if (mode === 'YMD2MDY')
            return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY')
        else {
            return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
        }
    } catch (e) {
        console.log(e)
    }
};

export const handleEditPopupData = (data, editData, dateRef, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef) => {
    let isErrorStat = false;
    const selectedDate = data.date || editData['date']

    if (selectedDate) {
        let dayname = moment(selectedDate, 'MM/DD/YYYY').format('dddd');

        if (dayname) {
            data.date = selectedDate;
            data.day = dayname;
        }
    }

    data.startTime = editData['startTime'] || data.startTime;
    data.endTime = editData['endTime'] || data.endTime;

    data.functionType = editData['functionType'] || data.functionType;
    data.peopleCount = Number(editData['peopleCount'] || data.peopleCount);

    data.postage = editData['postage'] || data.postage;
    data.setupStyle = editData['setupStyle'] || data.setupStyle;
    data.comments = editData['comments'] || data.comments;

    return { data, isErrorStat };
};

export const handleEditRecords = (gridRef, selectedNodes, editData, copyRecordData, ref) => {
    let { dateRef, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef } = ref
    let errorStat = false
    let recToUpdate = []
    gridRef.current.api.forEachNodeAfterFilterAndSort(
        function (rowNode, index) {
            for (const enode of selectedNodes) {
                if (enode['rowIndex'] === index) {
                    let { data, isErrorStat } = handleEditPopupData(rowNode.data, editData, dateRef, startTimeRef, endTimeRef, functionTypeRef, peopleCountRef)
                    errorStat = isErrorStat
                    if (!errorStat) {
                        const formatted_date = datePickerFormat(editData['date'] || rowNode.data['date'])
                        copyRecordData = updateEventRecords(copyRecordData, data.id, formatted_date, data);
                        recToUpdate.push(data);
                    }
                }
            }
        },
    );

    return { errorStat, copyRecordData, recToUpdate }
}

export const getUpdatedEvents = (generateState) => {
    const updatedEvents = _.chain(generateState?.finalJsonOutput?.events)
        .thru((events) => addUniqueIdToEvents(events))
        .thru((events) => assignEventDayNumbers(events))
        .value();

    // Clone the finalJsonOutput to avoid mutating the original state directly
    const updatedFinalJsonOutput = {
        ...generateState.finalJsonOutput,
        events: updatedEvents  // Update the events array with the modified events
    };

    return updatedFinalJsonOutput
}

export const validateStartEndDate = (start_date, end_date) => {
    return start_date !== '' && end_date !== '' && (moment(end_date, "YYYY-MM-DD").unix() - moment(start_date, "YYYY-MM-DD").unix()) < 0 ? false : true
}

export const validateDate = (date, mode = 'D') => {
    const date_format = mode === 'D' ? "YYYY-MM-DD" : "MM/DD/YYYY"
    return moment(date, date_format).isValid()
}

export const getDateErrMsg = (start_date, end_date) => {
    let error_msg = ""

    let valid_start_dt = start_date && !validateDate(start_date) ? false : true
    let valid_end_dt = end_date && !validateDate(end_date) ? false : true

    if (!valid_start_dt && valid_end_dt)
        error_msg = "Invalid start date."
    else if (valid_start_dt && !valid_end_dt)
        error_msg = "Invalid end date."
    else if (!valid_start_dt && !valid_end_dt)
        error_msg = "Invalid start and end date."
    else
        error_msg = !validateStartEndDate(start_date, end_date) ? "End date should be greater than Start date." : ""

    return error_msg
}

export const addDefaultGridValue = (gridData) => {
    let num = 0
    if (gridData && gridData.length > 0 && gridData[0]) {
        for (const row of gridData) {
            if (!row['peopleCount'] || Number(row['peopleCount']) === 0 || Number(row['peopleCount']) < 0) {
                gridData[num]['peopleCount'] = 1
            }
            num++
        }
    }
    return gridData
}