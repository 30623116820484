import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import NotFound from "./NotFound";

const NotFoundOrRedirect = () => {

    const { isAuthenticated, isSessionExpired, userRecords } = useContext(AuthContext);
    let expires_at = (userRecords?.expiresAt || "") || null;

    if (!isAuthenticated || isSessionExpired(expires_at)) {
        return <Navigate to="/login" />;
    }

    return <NotFound />
};

export default NotFoundOrRedirect;