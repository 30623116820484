import axios from 'axios'

console.log(process.env)
export const API_BASE_URL = process.env.REACT_APP_GENAI_SALES_AGENDA_API_BASE_URL;
console.log(`test unique key grid-siv`);

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-type': 'application/json'
    }
});

export const fetchData = async (url, params={}, headers= {}) => {
    try {
        return await axiosInstance.get(url, {params, headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error fetching data:', error);
        throw error;
    }

}

export const postAxiosCall = async (url, data={}, headers= {}) => {
    try {
        return await axiosInstance.post(url, data, {headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error posting data:', error);
        throw error;
    }
}

export const putAxiosCall = async (url, data={}, headers= {}) => {
    try {
        return await axiosInstance.put(url, data, {headers});
    } catch (error) {
        // Handle the error as needed
        console.error('Error posting data:', error);
        throw error;
    }
}
