
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation, Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

export default function LoginCallBack(props) {
    const navigate = useNavigate();
    const location = useLocation()
    const [searchParams] = useSearchParams();
    const [authStatus, setAuthStatus] = useState('Logging In...');
    const { setIsAuthenticated, setUserRecords, addUserSession  } = useContext(AuthContext);

    useEffect(() => {
        // Set expiry time

        // Extract the token related info from the callback url
        let accessToken = searchParams.get('access_token')
        let userInitial = searchParams.get('user_initial')
        let expiresAt = searchParams.get('expires_at')
        let expiresIn = searchParams.get('expires_in')
        let userName = searchParams.get('user_name')
        // let token_id = searchParams.get("token_id")

        if (accessToken) {
            // Set cookies
            addUserSession({ accessToken, userInitial, expiresAt, expiresIn, userName }, { path: "/", expires:expiresIn })
            setUserRecords({ accessToken, userInitial, expiresAt, expiresIn, userName })
            setAuthStatus('Authenticated');
            setIsAuthenticated(true);

            return navigate('/upload', { state: { from: location, userName: userName, userInitial } });
        } else {
            setAuthStatus('Authentication Failed');
            setIsAuthenticated(false);
        }
    }, [navigate, authStatus, searchParams, location, addUserSession, setIsAuthenticated, setUserRecords]);
    return (
        <>
            <div className="card-body p-5 text-center">
                {authStatus === 'Logging In...' && (
                    <div className="alert alert-info" role="alert">
                        {authStatus}
                    </div>
                )}

                {authStatus === 'Authenticated' && (
                    <div className="alert alert-success" role="alert">
                        {authStatus}
                    </div>
                )}

                {authStatus === 'Authentication Failed' && (
                    <div className="alert alert-danger" role="alert">
                        {authStatus} - <h6 className="txt-left" style={{ color: 'red' }}>Something Went Wrong.
                        Please navigate to <Link to="/login">login</Link> page and try again.</h6>
                    </div>
                )}
                <div style={{ clear: 'both' }}></div>
            </div>
        </>
    )
}