import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Select from 'react-select';
import ReactDOM from 'react-dom';
import * as _ from "lodash";

export const CustomAgGridDropDownEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const selectRef = useRef();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        // focus on the input
        ReactDOM.findDOMNode(selectRef.current).focus();
        setReady(true);
    }, []);

    useImperativeHandle(ref, () => ({
        // ag-Grid will call this function to get the cell's value
        getValue: () => {
            return value;
        }
    }));

    const handleChange = (selectedOption) => {
        if (ready) {
            setValue(selectedOption.value);
        }
    };

    return (
        <>
            <Select style={{height: "100%", position:"absolute"}}
                    className="basic-single"
                    classNamePrefix="select"
                    ref={selectRef}
                    autoFocus
                    defaultValue={{ value: "", label: "" }}
                    options={props.values.map(val => ({ value: val, label: val }))}
                    value={{value: `${_.includes(props.values, value) ? value: ""}`,
                        label: `${_.includes(props.values, value) ? value: ""}`}}
                    // options={props.values}
                    onChange={handleChange}
                    isSearchable={true}
                    menuPortalTarget={document.body} // to ensure the dropdown is not constrained by ag-Grid's cell
            />
        </>
    );
});