import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Draggable from "react-draggable";
import moment from 'moment';
import { functionTypes, setUpStyles } from '../utils/common-utils';

const BulkEditDialog = ({
                            editData,
                            handleDateChange,
                            handleEditChange,
                            handleSubmit,
                            // selectedNodes,
                            resetData,
                            onClose,
                            dateRef,
                            startTimeRef,
                            endTimeRef,
                            functionTypeRef,
                            peopleCountRef,
                            // handleFinalBulkEditSubmit
                        }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleLocalDateChange = (date) => {
        if (!date || !moment(date).isValid()) {
            console.log('dfdfdf');
            dateRef.current.textContent = "Please enter date in MM/DD/YYYY format";

            setSelectedDate(null);
            handleDateChange(null); // Clear the parent component's date value
            setIsCalendarOpen(false); // Close calendar on invalid date
            return;
        }

        console.log("inside child component");
        dateRef.current.textContent = "";
        setSelectedDate(date);
        handleDateChange(date);
        setIsCalendarOpen(false); // This line closes the calendar after selection
    };

    // const handleLocalBulkEditSubmit = (data) => {
    //     console.log(data);
    //     console.log(`selectedNodes`, selectedNodes);
    //     console.log("edit data in teh submit function ",editData);
    //     handleFinalBulkEditSubmit(true);
    // }


    return (
        <Draggable>
            <div className='custom-ui' style={{
                "border": "1px solid #000",
                "padding": "25px",
                "background": "#fff",
                "borderRadius": "10px",
                "boxShadow": "rgba(0,0,0,0.2) 0 0 10px",
                "borderCollapse": "collapse"
            }}>
                <h6>Bulk Edit</h6>
                <div className="container" style={{ "padding": "0px" }}>
                    <div className='dataRow'>
                        <div className="row">
                            <div className="col-sm editBox">Date</div>
                            <div className="col-sm editBox">Start Time</div>
                            <div className="col-sm editBox">End Time</div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox">
                                <div className="datePickerHolder">

                                    <DatePicker
                                        selected={selectedDate}
                                        value={inputValue}
                                        onChange={handleLocalDateChange}
                                        onChangeRaw={(event) => {
                                            setInputValue(event.target.value)
                                            if (event.key === 'Enter') {
                                                const manualDate = moment(
                                                    event.target.value,
                                                    'MM/DD/YYYY',
                                                    true
                                                );
                                                handleLocalDateChange(
                                                    manualDate.isValid() ? manualDate.toDate() : null
                                                );
                                            }
                                        }}
                                        onBlur={(event) => {
                                            const manualDate = moment(
                                                event.target.value,
                                                'MM/DD/YYYY',
                                                true
                                            );
                                            handleLocalDateChange(
                                                manualDate.isValid() ? manualDate.toDate() : null
                                            );
                                        }}
                                        strictParsing={true}
                                        className='datepicker'
                                        name="datebulkedit"
                                        id="calDPID"
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                        showIcon
                                        toggleCalendarOnIconClick={true}
                                        open={isCalendarOpen}
                                        onCalendarOpen={() => setIsCalendarOpen(true)}
                                        onCalendarClose={() => setIsCalendarOpen(false)}
                                        onClickOutside={() => setIsCalendarOpen(false)}
                                        onInputClick={() => {
                                            setIsCalendarOpen(true);
                                            dateRef.current.textContent = "";
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm editBox">
                                <input
                                    type="time"
                                    onChange={handleEditChange}
                                    name="startTime"
                                    className='editinput'
                                    aria-label="startTime"
                                />
                            </div>
                            <div className="col-sm editBox">
                                <input
                                    type="time"
                                    onChange={handleEditChange}
                                    name="endTime"
                                    className='editinput'
                                    aria-label="endTime"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox editBoxError" ref={dateRef}></div>
                            <div className="col-sm editBox editBoxError" ref={startTimeRef}></div>
                            <div className="col-sm editBox editBoxError" ref={endTimeRef}></div>
                        </div>
                    </div>

                    <div className='dataRow'>
                        <div className="row">
                            <div className="col-sm editBox">Function Type</div>
                            <div className="col-sm editBox">Post As/Signage</div>
                            <div className="col-sm editBox">Setup Style</div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox">
                                <Select
                                    options={functionTypes.map(val => ({
                                        value: val,
                                        label: val,
                                        name: 'functionType'
                                    }))}
                                    onChange={handleEditChange}
                                    name="functionType"
                                    isSearchable={true}
                                    className='editinput'
                                />
                            </div>
                            <div className="col-sm editBox">
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    name="postage"
                                    className='editinput'
                                />
                            </div>
                            <div className="col-sm editBox">
                                <Select
                                    options={setUpStyles.map(val => ({
                                        value: val,
                                        label: val,
                                        name: 'setupStyle'
                                    }))}
                                    onChange={handleEditChange}
                                    name="setupStyle"
                                    isSearchable={true}
                                    className='editinput'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox editBoxError" ref={functionTypeRef}></div>
                        </div>
                    </div>

                    <div className='dataRow'>
                        <div className="row">
                            <div className="col-sm editBox">Expected</div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox">
                                <input
                                    type="text"
                                    onChange={handleEditChange}
                                    name="peopleCount"
                                    className='editinput'
                                    aria-label="peopleCount"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm editBox editBoxError" ref={peopleCountRef}></div>
                        </div>
                    </div>

                    <div className='dataRow'>
                        <div className="row">
                            <div className="col-sm editBox">Comments</div>
                        </div>
                        <div className="row">
                            <div className="editBoxLarge">
                                <textarea
                                    className="edit_comments"
                                    onChange={handleEditChange}
                                    name="comments"
                                />
                            </div>
                        </div>
                    </div>

                    <div className='dataRow'>
                        <div className="row" style={{ "width": "710px" }}>
                            <div className="col-sm editBox"></div>
                            <div className="col-sm editBox"></div>
                            <div className="col-sm editBox" style={{
                                "textAlign": "right",
                                paddingTop: "23px",
                                "cursor": "pointer",
                                "width": "180px"
                            }}>
                                <a onClick={() => {
                                    resetData();
                                    onClose();
                                }} style={{ "textDecoration": "underline" }}>
                                    Cancel Edit
                                </a>
                            </div>
                            <div className="col-sm editBox" style={{ "textAlign": "right", "width": "180px" }}>
                                <button
                                    type="submit"
                                    className="btn btn-dark btn_edit_dark edit_conf"
                                    onClick={() => {
                                        // console.log('EditData before submit:', editData);
                                        handleSubmit(onClose, selectedDate);
                                    }}
                                    style={{ "fontSize": "11px" }}
                                >
                                    Bulk Edit Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default BulkEditDialog;
