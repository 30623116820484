import * as _ from "lodash";
import { CustomAgGridDropDownEditor } from "../components/CustomAgGridDropDownEditor";
import { CustomAgGridValidationRenderer } from "../components/CustomAgGridValidationRenderer";
import { CustomLargeTextCellEditor } from "../components/CustomLargeTextCellEditor";
import {
    timeValueParser, functionTypes, setUpStyles
} from '../utils/common-utils';
let dayNo = 0

export const suppressEnter = (params) => {
    let KEY_ENTER = 'Enter';
    let event = params.event;
    let key = event.key;
    return key === KEY_ENTER;
};

const gridCommon = {
    filter: true,
    editable: true,
    sortable: true,
    unSortIcon: true,
}


export const gridSettings = [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        editable: false,
        width: 50,
        minWidth: 30,
        maxWidth: 50,
        suppressSizeToFit: true,
        resizable: false,
        lockPosition: true,
        suppressMenu: true,
    },
    {
        headerName: "Day #",
        field: "dayNumber",
        ...gridCommon,
        cellStyle: params => {
            return { textAlign: 'left' };
        },
    },
    {
        headerName: "Day",
        field: "day",
        filter: true,
        editable: false,
        cellStyle: params => {
            return { textAlign: 'left' };
        }
    },
    {
        headerName: "Date",
        field: "date",
        ...gridCommon,
        cellRendererParams: {
            'columnName': 'Date',
            'validationMessage': 'Date Format (MM/DD/YYYY)'
        },
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }
            return { textAlign: 'left' };
        },
        cellEditorParams: {
            dateFormat: 'MM/DD/YYYY' // Format for the date picker
        },
        cellEditor: 'agDateStringCellEditor',
    },
    {
        headerName: "Start Time",
        field: "startTime",
        ...gridCommon,
        cellRenderer: CustomAgGridValidationRenderer,
        cellRendererParams: {
            'columnName': 'startTime',
            'validationMessage': '(HH:MM AM or PM)'
        },
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }
            return { textAlign: 'left' };
        },
        valueParser: timeValueParser
    },
    {
        headerName: "End Time",
        field: "endTime",
        ...gridCommon,
        cellRenderer: CustomAgGridValidationRenderer,
        cellRendererParams: {
            'columnName': 'endTime',
            'validationMessage': '(HH:MM AM or PM)'
        },
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }
            return { textAlign: 'left' };
        },
        valueParser: timeValueParser
    },
    {
        headerName: "Function Type",
        field: "functionType",
        ...gridCommon,
        cellRenderer: CustomAgGridValidationRenderer,
        cellRendererParams:  (params) => {
            return {
                'columnName': 'functionType',
                'dataToValidate': functionTypes,
                'validationMessage': params && params.value && params.value.length > 0 ? 'Invalid entry: Not Found' : 'Required'
            }
        },
        // cellEditorPopup: true,
        cellEditor: CustomAgGridDropDownEditor,
        cellEditorParams: {
            values: functionTypes
        },
        suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
        },
        tooltipValueGetter: (params) => params && params.value && params.value.length > 16 ? params.value : "",
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left', paddingTop: '20px' };
            }
            
            if(params.value !== '' && functionTypes.includes(params.value) === false) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }

            return { textAlign: 'left' };
        }
    },
    {
        headerName: "Post As/Signage",
        field: "postage",
        ...gridCommon,
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }
            return { textAlign: 'left' };
        },
        tooltipValueGetter: (params) => params && params.value && params.value.length > 16 ? params.value : ""
    },
    {
        headerName: "Set-Up Style",
        field: "setupStyle",
        ...gridCommon,
        cellRenderer: CustomAgGridValidationRenderer,
        cellRendererParams: (params) => {
            return {
                'columnName': 'setUpStyle',
                'dataToValidate': setUpStyles,
                'validationMessage': params && params.value && params.value.length > 0 ? 'Invalid entry: Not Found' : 'Required'
            }
        },
        cellEditor: CustomAgGridDropDownEditor,
        cellEditorParams: {
            values: ["", ...setUpStyles],
        },
        suppressKeyboardEvent: (params) => {
            return suppressEnter(params);
        },
        tooltipValueGetter: (params) => params && params.value && params.value.length > 16 ? params.value : "",
        cellStyle: params => {

            if (params.value === '' || _.isNil(params.value)) {
                return { backgroundColor: '#ffeebe', textAlign: 'left', paddingTop: '20px' };
            }

            if(params.value !== '' && setUpStyles.includes(params.value) === false) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }

            return { textAlign: 'left' };
        }
    },
    {
        headerName: "Expected",
        field: "peopleCount",
        ...gridCommon,
        cellStyle: params => {
            if (params.value === '' || _.isNil(params.value) ) {
                return { backgroundColor: '#ffeebe', textAlign: 'left' };
            }
            return { textAlign: 'left' };
        },
        cellEditorParams: {
            min: 1
        }
    },
    {
        headerName: "Comments",
        field: "comments",
        filter: true,
        editable: true,
        cellEditor: CustomLargeTextCellEditor,
        cellEditorPopup: true,
        cellEditorParams: {
            rows: 15,
            cols: 50,
            maxLength: 250
        },
        tooltipValueGetter: (params) => params && params.value && params.value.length > 16 ? params.value : "",
        cellStyle: params => {
            return { textAlign: 'left' };
        }, flex: 2
    },
]