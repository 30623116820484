import AuthContext from "../context/AuthContext";
import {useContext} from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {

    const { isAuthenticated, isSessionExpired, userRecords } = useContext(AuthContext);
    let expires_at = userRecords?.expiresAt ?? null;
    let isAccessTokenFound = !!userRecords['accessToken'];


    if (!isAccessTokenFound && (!isAuthenticated || isSessionExpired(expires_at))) {
        return <Navigate to="/login" />;
    }



    return children
};

export default ProtectedRoute;